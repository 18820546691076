import * as React from 'react';
import { FC } from 'react';
import { CTANewsletterFragment, NewsletterPageFragment, NewsletterPageQuery } from '../entities/operationResults';
import { graphql, navigate } from 'gatsby';
import Meta from './atoms/Meta';
import withDefaultTransition from '../hocs/withDefaultTransition';
import loadable from '@loadable/component';

const NewsletterWizard = loadable(() => import('./organisms/NewsletterWizard'));

export const query = graphql`
    query NewsletterPageQuery($site: String, $uri: String) {
        craft {
            entry(site: $site, uri: $uri) {
                ...NewsletterPageFragment
            }
            entries(site: $site, type: CTANewsletter) {
                ...CTANewsletterFragment
            }
        }
    }
`;

export const fragment = graphql`
    fragment NewsletterPageFragment on Craft_Newsletter {
        title
        seo {
            ...MetaFragment
        }
    }
`;

interface Props {
    data: NewsletterPageQuery;
}

const Newsletter: FC<Props> = ({ data }) => {
    const cta: CTANewsletterFragment = data.craft?.entries?.[0] as CTANewsletterFragment;
    const entry = data.craft.entry as NewsletterPageFragment;
    const meta = entry.seo?.[0];

    return (
        <>
            <Meta
                title={meta?.seoTitle ?? entry.title}
                description={meta?.seoDescription ?? null}
                image={meta?.seoImage ?? null}
            />

            <NewsletterWizard showPopover data={cta} setShowPopover={setShowPopover} variant="light" withDelay />
        </>
    );
};

const setShowPopover = (value: boolean) => {
    if (!value) {
        navigate('/');
    }
};

export default withDefaultTransition(Newsletter);
